@font-face {
  font-family: CentraleSans;
  src: url("./assets/fonts/CentraleSansRegular.otf") format("opentype");
}

@font-face {
  font-family: CentraleSans;
  font-weight: bold;
  src: url("./assets/fonts/CentraleSansBold.otf") format("opentype");
}

:root {
  --primary-color: #ced665;
  --white-color: #ffffff;
  --highlighted-text-color: #375e77;
  --grey: #645c59;
  --light-grey: rgba(249, 249, 251, 1);
  --light-bluegrey: #dae6ed;
  --dark-grey: #5E5E60;
}

body {
  margin: 0;
  font-family: CentraleSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a { 
  color: var(--highlighted-text-color);
  text-decoration: none;
}
  
a:hover {
  text-decoration: underline;
}

.highlighted-text {
  color: var(--highlighted-text-color) !important;
}
